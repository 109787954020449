import React from 'react';
import milaBox from 'assets/images/mila-box.png';
import milaBoxWebp from 'assets/images/mila-box.png?as=webp';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { getTrue } from '../../../shared/utils';
import {
  CURRENCY_SIGN, EMOJI, PRODUCTS_ID_MAPPING, SUPPORTED_REGIONS,
} from '../../../shared/enums';
import { selectLocalizationData } from '../../features/localization/localizationSlice';
import {
  selectRegionalPricesData, selectRegionalPricesError,
} from '../../features/regionalPrices/regionalPricesSlice';

const FadeIn = loadable(() => import('./FadeIn'));

/**
 * @returns {null|JSX.Element}
 * @constructor
 */
const OrderBlock = () => {
  const localization = useSelector(selectLocalizationData);
  const regionalPrices = useSelector(selectRegionalPricesData);
  const error = useSelector(selectRegionalPricesError);

  const sign = CURRENCY_SIGN[localization.region] || CURRENCY_SIGN.US;
  const unit = regionalPrices.find(price => price.variant === PRODUCTS_ID_MAPPING.MILA_UNIT);
  const basicBreather = regionalPrices.find(
    price => price.variant === PRODUCTS_ID_MAPPING.BASIC_BREATHER,
  );

  const style = localization.region !== SUPPORTED_REGIONS.SG ? {} : {
    marginBottom: '15px',
    boxShadow: '0 32px 44px rgb(0 0 0 / 5%)',
  };

  return error ? null : (
    <section className='order-block order-block--bottom-space'>
      <div className='order-block__inner'>
        <div className='container'>
          <div className='order-block__content'>
            <h2 className='order-block__title'>
              Now shipping. Get yours in
              {' '}
              {unit.shipping_timeline}
              .
              {' '}
              {EMOJI.ROCKET}
            </h2>
            <div className='order-block__description'>
              <p>It’s easy and risk free, with our Mila-backed guarantee.</p>
            </div>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source srcSet={milaBoxWebp} type='image/webp' />
                  <source srcSet={milaBox} type='image/png' />
                  <img
                    src={milaBox}
                    alt='mila box in white'
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </div>
          <div className='order-block__card'>
            <div className='order-block__card-primary' style={style}>
              <h3 className='order-block__card-title'>
                <strong>Purchase Mila</strong>
                {' '}
                starting at just
              </h3>
              <p className='order-block__card-price'>
                <span className='order-block__price-amount'>
                  {(getTrue(ENABLE_DISCOUNT) && unit.discount_price) && (
                    <span
                      className='order-block__discount-price'
                      style={{
                        color: '#979797',
                        marginRight: '15px',
                        verticalAlign: 'middle',
                        overflow: 'hidden',
                        position: 'relative',
                      }}
                    >
                      {sign}
                      {unit.price}
                    </span>
                  )}
                  {(getTrue(ENABLE_DISCOUNT) && unit.discount_price) ? sign : (<span className='currency-symbol'>{sign}</span>)}
                  {(getTrue(ENABLE_DISCOUNT) && unit.discount_price) ? unit.discount_price : unit.price}
                </span>
                Per unit
              </p>
              {localization.region !== SUPPORTED_REGIONS.SG && (
                <svg className='icon icon-plus'>
                  <use xlinkHref='#icon-plus' />
                </svg>
              )}
            </div>
            {localization.region !== SUPPORTED_REGIONS.SG && (
              <div className='order-block__card-secondary'>
                <div className='order-block__secondary-inner'>
                  <h3 className='order-block__card-title'>
                    <strong>Get fresh filters delivered</strong>
                    Starting at
                  </h3>
                  <p className='order-block__card-price'>
                    <span className='order-block__price-amount order-block__price-amount--alt'>
                      <span className='currency-symbol'>{sign}</span>
                      {basicBreather.price}
                    </span>
                    Every
                    6 months
                  </p>
                </div>
                <p className='order-block__note'>
                  <strong>
                    Your first filter is free
                    {EMOJI.HUGGING_FACE}
                  </strong>
                  {' '}
                  and you can cancel any time.
                </p>
              </div>
            )}
            <a href='/buy' className='btn btn--order btn--fullwidth'>Order Now</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderBlock;
